<template>
  <div>
    <th-login />
  </div>
</template>
<script>
import ThLogin from '@/components/Auth/Login'
export default {
  components: {
    ThLogin,
  },
}
</script>
